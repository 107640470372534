import React from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Link } from '~/techstyle-shared/next-routes';
import { Button } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import BuilderLocalizedText from '../BuilderLocalizedText';

const CtaNodeWrapper = styled.div``;

const StyledButton = styled(Button)`
  grid-column-end: span 1;
  max-width: 200px;
`;

const linkStyle = css`
  color: ${({ theme }) => theme.colors.linkText};
  font-weight: 700;
`;

const YittyLink = styled.a`
  ${linkStyle}
`;

const DynamicFormattedMessage = FormattedMessage;

export default function CtaNode({
  defaultMessage,
  messageId,
  variantStyle = 'primary',
  targetUrl = '/',
  ...rest
}) {
  const router = useRouter();
  const isTextLink = variantStyle === 'text_link';

  const message = () =>
    messageId ? (
      <DynamicFormattedMessage defaultMessage={defaultMessage} id={messageId} />
    ) : (
      <BuilderLocalizedText text={defaultMessage} />
    );

  return isTextLink ? (
    <CtaNodeWrapper {...rest}>
      <Link href={targetUrl} passHref>
        <YittyLink>{message()}</YittyLink>
      </Link>
    </CtaNodeWrapper>
  ) : (
    <StyledButton
      variant={variantStyle}
      onClick={() => router.push(targetUrl)}
      {...rest}
    >
      {message()}
    </StyledButton>
  );
}

CtaNode.propTypes = {
  defaultMessage: PropTypes.string,
  messageId: PropTypes.string,
  targetUrl: PropTypes.string,
  variantStyle: PropTypes.string,
};
